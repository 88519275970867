import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, RouteConstants } from '@app/core';
import {
  AddEditQuickLinkItemComponent, 
  AnnouncementsGridComponent,
  ActivityFeedComponent,
  HomeComponent, 
  HomeDashboardComponent,
  KnowledgeBaseAddItemComponent,
  KnowledgeBaseComponent,
  PreSupportTicketComponent,
  QuickLinksComponent,
  SupportComponent,
  SupportAddTicketComponent,
  SupportTicketDetailsComponent,
  SupportTicketWizardComponent,
  TasksActionItemsComponent, 
} from './home';
import { PublicComponent, UserAutoLogoutModalComponent, UserSelectDisciplineModalComponent } from './public';
import { AppCustomPreloadingStrategy } from './app-custom-preloading-strategy';
import { NotFoundComponent } from './not-found/not-found.component';


const routes: Routes = [
  {
    component: PublicComponent,
    path: 'public',
    pathMatch: 'full',
  },
  {
    component: HomeComponent,
    path: RouteConstants.HOME,
    canActivate: [AuthGuard],
    children: [
      {
        component: HomeDashboardComponent,
        path: RouteConstants.HOME_ANNOUNCEMENTS,
        pathMatch: 'full',
        data: {
          tabIndex: 0,
        },
      },
      {
        component: SupportComponent,
        path: RouteConstants.HOME_SUPPORT,
        pathMatch: 'full',
        data: {
          tabIndex: 1,
        }
      },
      {
        component: KnowledgeBaseComponent,
        path: RouteConstants.HOME_KNOWLEDGE,
        pathMatch: 'full',
        data: {
          tabIndex: 2,
        },
      },
      {
        component: QuickLinksComponent,
        path: RouteConstants.HOME_QUICK_LINKS,
        pathMatch: 'full',
        data: {
          tabIndex: 3,
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'announcements',
      }
    ]
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canLoad: [AuthGuard],
    data: { preload: true },
  },
  { 
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { 
    path: '**',
    component: NotFoundComponent,
  },
];

const config: ExtraOptions = {
  preloadingStrategy: AppCustomPreloadingStrategy,
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

export const routedComponents = [
  AddEditQuickLinkItemComponent,
  AnnouncementsGridComponent,
  ActivityFeedComponent,
  HomeComponent,
  HomeDashboardComponent,
  KnowledgeBaseAddItemComponent,
  KnowledgeBaseComponent,
  NotFoundComponent,
  PreSupportTicketComponent,
  PublicComponent,
  QuickLinksComponent,
  SupportComponent,
  SupportAddTicketComponent,
  SupportTicketDetailsComponent,
  SupportTicketWizardComponent,
  TasksActionItemsComponent,
  UserAutoLogoutModalComponent,
  UserSelectDisciplineModalComponent,
]
